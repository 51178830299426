import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Card, Row, Col } from "reactstrap";

import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import '../Template.scss';
import * as $ from 'jquery';

const TestHeader = (props) => {
    const { data, tabId } = props;
    
    // const scrollListener = () => {
    //     const headerElem = $("#"+ tabId);
    //     const lockOffset = headerElem.offset().top-80;
    //     $(window).scroll( function() {
    //         const currentScroll = $(window).scrollTop();
    //         //const currentScroll = document.documentElement.scrollTop;            
    //         // console.log('currentScroll:',currentScroll,' lockOffset:',lockOffset)

    //         if (currentScroll >= lockOffset) {
    //             headerElem.css('width', headerElem.outerWidth()+'px');
    //             headerElem.addClass('locked');
    //         } else {
    //             headerElem.removeClass('locked');
    //             headerElem.css('width', 'initial');
    //         }
    //     });
    // };

    // useEffect(() => {
    //     if(data){
    //         window.addEventListener('scroll', scrollListener);    
    //         return () => {
    //             window.removeEventListener('scroll', scrollListener);
    //         };
    //     }
    //   }, [data]);

    return (
        <div>
            <Card id={tabId} className=''>
                <Row className='py-2 text-gray d-flex align-items-center'>
                    {data}
                </Row>
            </Card>
        </div>       
    )

}


export default TestHeader;