import React, { useState } from 'react';
import { Card, Row, Col, Button, Tooltip } from "reactstrap";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { isMobile } from 'react-device-detect';
import '../Template.scss';
import numeral from 'numeral';
import IconBadgeSolid from '../../../../../SharedComponents/IconBadge/IconBadgeSolid';
import { MdInfo } from 'react-icons/md';
import { colors } from '../../../../../config/constants';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';

const TestRow = (props) => {
    const { data, style, id } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    
    return (
       <Card className='p-2'>
           <ShowIf show={!isMobile}>
                <Row style={{fontSize: style}}>
                    <Col xs={1}> {data.din} </Col>
                    <Col xs={2} className='px-0 text-capitalize text-truncate'> {data.item_name}</Col>
                    <Col xs={1}> {data.unit_measure}</Col>
                    <Col xs={1}> {numeral(data.price).format('$0,0.00')}</Col>
                    <Col xs={1}> {data.unit_quantity + '/' + data.unit_size + ' ' + data.pack_size_unit}</Col>

                    <Col xs={1}>{data.unit_quantity}</Col>
                    <Col xs={1}>{data.unit_size}</Col>
                    <Col xs={1}>{data.pack_size_unit}</Col>

                    <Col xs={1}> {numeral(data.unit_price).format('$0,0.00')}</Col>
                    <Col xs={1}> {!data.is_catch_weight ? 'No' : 'Yes'}</Col>
                    <Col xs={1} className='text-capitalize text-truncate'>
                        <MdInfo className='clickable' size={20} color={colors.lightBlueText} id={`info_${id}`}/>
                        <Tooltip placement="right" isOpen={tooltipOpen} target={`info_${id}`} toggle={toggle} style={{width:'25em', maxWidth:'25em'}}>
                            <Row className='pt-2 px-1 text-12'>
                                <Col className='text-left'>Brand</Col>
                                <Col className='text-right'>{data.brand}</Col>
                            </Row>
                            <Row className='px-1 text-12'>
                                <Col className='text-left'>Category</Col>
                                <Col className='text-right'>{data.category}</Col>
                            </Row>
                            <Row className='px-1 text-12'>
                                <Col className='text-left'>Market Price</Col>
                                <Col className='text-right'>{data.is_market_price ? 'yes' : 'no'}</Col>
                            </Row>
                            <Row className='px-1 text-12'>
                                <Col className='text-left'>Avg Weight</Col>
                                <Col className='text-right'>{numeral(data.average_weight).format('0,0.00')}</Col>
                            </Row>
                            <Row className='px-1 text-12'>
                                <Col className='text-left'>Net Weight</Col>
                                <Col className='text-right'>{numeral(data.net_weight).format('0,0.00')}</Col>
                            </Row>
                        </Tooltip>

                        {data.brand}
                    </Col>
                    {/* <Col xs={1} className='text-capitalize text-truncate'> {data.category}</Col> */}
                </Row>
            </ShowIf>
            
       </Card>
    )

}

TestRow.defaultProps = {
    style: '14px',
};

export default TestRow;