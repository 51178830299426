
import React, {Fragment, useState, useEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Container } from 'reactstrap';
import useSkyloader from '../useSkyloader';
import SkyloaderQueueRow from "./SkyloaderQueueRow";
import SkyloaderQueueHeader from "./SkyloaderQueueHeader";
import InfiniteScroll from 'react-infinite-scroller';

const SkyloaderQueue = (props) => {    
    const apiResult = useSkyloader.useApi(props);

    const [skyloaderQueueMap, setSkyloaderQueueMap] = useState('');
    const { skyloaderQueue, getQueue, page, isLoading, loadMore } = apiResult;


    useEffect(() => {
        setSkyloaderQueueMap(skyloaderQueue.map(t => <SkyloaderQueueRow  key={t.id} id={t.id} data={t} {...apiResult} />));
    }, [skyloaderQueue])

    return (
        <Fragment>
            <SkyloaderQueueHeader/>
            <InfiniteScroll
                initialLoad = {false}
                pageStart={page}
                loadMore={getQueue}
                hasMore={loadMore && !isLoading}
                useWindow={true}
            >
                {skyloaderQueueMap}
            </InfiniteScroll>
        </Fragment>
    )
}

export default SkyloaderQueue;