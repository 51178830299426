
import React, {useState, useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { Row, Col } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';
import { useHistory } from 'react-router-dom';

const SkyloaderQueueDetailHeader = (props) => {
    
    const history = useHistory();    
    const section = history.location.pathname.split('/');
    const id = section[section.length-2];
    
    return (
        <Header showMenu={false} showLogo={isMobile ? false : true} showBack={true} title={`Skyloader (${id})`} >
        </Header>
    )
}

export default SkyloaderQueueDetailHeader;