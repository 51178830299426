
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import SkyloaderQueue from "./SkyloaderQueue/SkyloaderQueue";
import SkyloaderQueueDetail from "./SkyloaderQueueDetail/SkyloaderQueueDetail";


const Skyloader = (props) => {
    
    return (
        <Switch>        
            <Route exact path='/skyloader-queue'>
                <Container fluid={true} className={isMobile ? 'px-0' : ''}>
                    <SkyloaderQueue/>
                </Container>
            </Route>

            <Route exact path={`/skyloader-queue/:id/detail`}>
                <Container fluid={true} className={isMobile ? 'px-0' : ''}>
                    <SkyloaderQueueDetail/>
                </Container>
            </Route>
            
            {/* <Route>
                <Redirect to='/SkyloaderQueue' />
            </Route> */}
        </Switch>
        
    )
}

export default Skyloader;