import React, {useState, Fragment} from 'react';
import { Card, Row, Col, Button, Tooltip } from "reactstrap";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { MdReplay, MdCloudDownload, MdDelete } from 'react-icons/md';
import { IoIosEye } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa';
import { colors } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useSkyloader from '../useSkyloader';
import '../Skyloader.scss';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';

const SkyloaderQueueRow = (props) => {
    const { data } = props;
    const dispatch = useDispatch();
    const { setHover, hover, viewTemplate, openSettings, downloadTemplate, deleteTemplate } = useSkyloader.useRow(props);

    const [tooltipOpenSet, setTooltipOpenSet] = useState(false);
    const toggleSet = () => setTooltipOpenSet(!tooltipOpenSet);

    const [tooltipOpenView, setTooltipOpenView] = useState(false);
    const toggleView = () => setTooltipOpenView(!tooltipOpenView);

    const [tooltipOpenDown, setTooltipOpenDown] = useState(false);
    const toggleDown = () => setTooltipOpenDown(!tooltipOpenDown);

    // const [tooltipOpenDel, setTooltipOpenDel] = useState(false);
    // const toggleDel = () => setTooltipOpenDel(!tooltipOpenDel);

    // const deleteFromList = (id) => {
    //     const questionProps = {
    //         message: `Are you sure you want to delete this parser?`,
    //         menu: isMobile ? true : false,
    //         leftButton: {
    //             title: 'Delete',
    //             color: 'orange',
    //             onClick: () => deleteTemplate(id)
    //         },
    //         rightButton: {
    //             title: 'Cancel',
    //             color: 'green',
    //         }
    //     };
    //     dispatch(showModal(QuestionModal, questionProps));
    // }

    return (
       <Card>
           <ShowIf show={!isMobile}>
                <Row className='py-3 px-3 d-flex align-items-center ' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <Col xs={3} className='text-capitalize'> {data.filename} </Col>
                    <Col xs={1} className='text-capitalize'> {!data.create_time ? '' : <Moment format="MM/DD/YYYY">{data.create_time}</Moment>} </Col>
                    <Col xs={2} className='text-capitalize'> {!!data.distributor ? data.distributor.nickname : ''} </Col>
                    <Col xs={2} className='text-capitalize'> {!!data.merchant ? data.merchant.nickname : ''} </Col>
                    <Col xs={1} className='text-capitalize'> {data.load_status} </Col>              
                    <Col xs={2} className='text-capitalize'>
                        <Row className='d-flex align-items-center text-gray' style={{fontSize:'0.9em'}}>
                            <Col className='text-center clickable'>                                
                                { 
                                    data.load_status !== 'processed' ? 
                                    (
                                        <Fragment>
                                            <MdReplay color={colors.orange} size={20} onClick={() => openSettings(data)} id='tooltip-set'/> 
                                            <Tooltip
                                                placement='bottom'
                                                isOpen={tooltipOpenSet}
                                                target='tooltip-set'
                                                toggle={toggleSet}
                                            >
                                            Retry
                                            </Tooltip>
                                        </Fragment>
                                    ) : 
                                    (
                                        <Fragment>
                                            <FaCheck color={colors.green} size={20} id='tooltip-set'/>
                                        </Fragment>
                                    )
                                }                                
                            </Col>
                            <Col className='text-center clickable'>
                                <IoIosEye color={colors.lightBlueText} size={20} onClick={() => viewTemplate(data.id)} id='tooltip-view'/>
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenView}
                                    target='tooltip-view'
                                    toggle={toggleView}
                                >
                                    View
                                </Tooltip>
                            </Col>
                            <Col className='text-center clickable' >
                                <MdCloudDownload color={colors.lightBlueText} size={20} onClick={() => downloadTemplate(data.file_url)} id='tooltip-down'/>
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenDown}
                                    target='tooltip-down'
                                    toggle={toggleDown}
                                >
                                    Download
                                </Tooltip>
                            </Col>
                            {/* <Col className='text-center clickable' >
                                <MdDelete color={colors.lightBlueText} size={20} onClick={() => deleteFromList(data.id)} id='tooltip-del'/>
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenDel}
                                    target='tooltip-del'
                                    toggle={toggleDel}
                                >
                                    Delete
                                </Tooltip>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </ShowIf>
            
       </Card>
    )

}

export default SkyloaderQueueRow;