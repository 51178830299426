import React, {Fragment, useState, useEffect} from 'react';
import { Container, Card, Row, Col, Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { colors } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useSkyloader from '../useSkyloader';
import '../Skyloader.scss';
import SkyloaderQueueDetailHeader from "./SkyloaderQueueDetailHeader";
import { MdCloudDownload } from 'react-icons/md';

const SkyloaderQueueDetail = (props) => {
    
    const history = useHistory();
    const section = history.location.pathname.split('/');
    const { data } = useSkyloader.useDetailApi(section[section.length-2]);
    const [detail, setDetail] = useState('');
    
    const downloadTemplate = (fileUrl) => {
        const excelElement = document.createElement('a');
        excelElement.href = fileUrl;
        excelElement.click();
    }

    useEffect(() => {
        if(!!data){
            setDetail(data);            
        }        
    }, [data]);

    return (
        <Fragment>
            <SkyloaderQueueDetailHeader id={detail.id}/>
            <ShowIf show={!isMobile}>
                <Row className='pt-4'>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> File name </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.filename} </Col>
                </Row>
                <Row className='pt-4'>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> DCN </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.dcn} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Distributor </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {!!detail.distributor ? detail.distributor.nickname : ''} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Merchant </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {!!detail.merchant ? detail.merchant.nickname : ''} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Start Date </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.start_date} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> End Date </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.end_date} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Load Status</Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> 
                        {detail.load_status == "processed" ? detail.load_status : (!!detail.errors ? detail.errors[0].error_reason : '')} 
                    </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Order Guide Id</Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.order_guide_id} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> File </Col>
                    <Col xs={8} className='py-1 text-left text-main'> 
                        <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={() => downloadTemplate(detail.file_url)}>
                            <MdCloudDownload color='white' size={20} /> Download
                        </Button>
                    </Col>
                </Row>
                
            </ShowIf>

        </Fragment>
       
    )

}

export default SkyloaderQueueDetail;