import axios from 'axios';

const get = (params) => {
  return axios.get('/skyloader',{params});
};

const view = (id) => {
    return axios.get(`/skyloader/${id}`);
  };


export default {
  get, view
  
};