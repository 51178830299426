import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import skyloaderApi from '../../../../api/skyloaderApi';
import { showModal } from "../../../../reducers/modals/modalsActions";
import { useSelector, useDispatch } from "react-redux";
import AlertModal from '../../../../SharedComponents/Modals/AlertModal/AlertModal';


const useApi = (props) => {
  const [skyloaderQueue, setSkyloaderQueue] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getQueue = () => {
    if(page>pageCount){ return;}
    const params = {
      page : page,
      limit : 20
    };
    setIsLoading(true);
    skyloaderApi.get(params).then((res) => {
      const temp = res.data.data;
      setSkyloaderQueue(skyloaderQueue.concat(temp.data));
      setPage(page+1);
      setPageCount(temp._meta.pageCount);
      setLoadMore(temp._meta.pageCount > temp._meta.currentPage);
      setIsLoading(false);
    })
    .catch(error => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getQueue();
  }, []);


  return { skyloaderQueue, setSkyloaderQueue, getQueue, page, isLoading, loadMore  };
}

const useDetailApi = (id) => {
    const [data, setData] = useState([]);
  
    const getSkyloader = () => {
      skyloaderApi.view(id).then((res) => {
        setData(res.data.data);
      });
    };
  
    useEffect(() => {
        getSkyloader();
    }, []);
  
  
    return { data, getSkyloader };
  }


const useRow = (props) => {

  const {data, SkyloaderQueue, setSkyloaderQueue} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [hover, setHover] = useState(false);

  const openSettings = (data) => {    
    // history.push({pathname: `/templates/${data.id}/settings`, state: {file:data}});
  };  


  const viewTemplate = (id) => {
    history.push(`/skyloader-queue/${id}/detail`);
  };

  const downloadTemplate = (fileUrl) => {
    const excelElement = document.createElement('a');
    excelElement.href = fileUrl;
    excelElement.click();
  }

  const deleteTemplate = (id) => {
    // skyloaderApi.delete(id).then((res) => {
    //     const test = res.data.data;
    //     setSkyloaderQueue(SkyloaderQueue.filter(temp => (temp.id != id)));
    // })
    // .catch(error => {
    
    // });
  }


  return {
    setHover, hover, openSettings, viewTemplate, downloadTemplate, deleteTemplate
  }

}

export default {
  useApi, useDetailApi, useRow,
};